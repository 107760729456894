"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
/**
 * @ignore
 */
var context = react_1.createContext({ flags: {}, ldClient: undefined });
var 
/**
 * @ignore
 */
Provider = context.Provider, 
/**
 * @ignore
 */
Consumer = context.Consumer;
exports.Provider = Provider;
exports.Consumer = Consumer;
exports.default = context;
