"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var context_1 = require("./context");
/**
 * withLDConsumer is a function which accepts an optional options object and returns a function
 * which accepts your React component. This function returns a HOC with flags
 * and the ldClient instance injected via props.
 *
 * @param options - If you need only the `ldClient` instance and not flags, then set `{ clientOnly: true }`
 * to only pass the ldClient prop to your component. Defaults to `{ clientOnly: false }`.
 * @return A HOC with flags and the `ldClient` instance injected via props
 */
function withLDConsumer(options) {
    if (options === void 0) { options = { clientOnly: false }; }
    return function withLDConsumerHoc(WrappedComponent) {
        return function (props) { return (React.createElement(context_1.Consumer, null, function (_a) {
            var flags = _a.flags, ldClient = _a.ldClient;
            if (options.clientOnly) {
                return React.createElement(WrappedComponent, __assign({ ldClient: ldClient }, props));
            }
            return React.createElement(WrappedComponent, __assign({ flags: flags, ldClient: ldClient }, props));
        })); };
    };
}
exports.default = withLDConsumer;
